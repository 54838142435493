@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
/* Imports */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto Mono", monospace;
}

#root {
  background: url(/static/media/backgroundimage.cd576bd4.jpg) no-repeat center center fixed;
  background-size: cover;
  color: #eaeaea;
  width: 100%;
  max-width: 1920px;
}

/* Navbar Styles */

#navbar-wrapper {
  display: flex;
  justify-content: space-between;
  height: 13vh;
  margin: 0 2rem;
  border-bottom: 5px solid #eaeaea;
}

.navbar-title {
  margin: 1rem 0;
  font-family: "Roboto Mono", monospace;
}

.navbar-list {
  display: flex;
}

.navbar-list li {
  padding-top: 1rem;
  list-style: none;
  display: inline-block;
  margin-top: 1rem;
  font-family: "Roboto Mono", monospace;
}

.navbar-list li:hover {
  /* background-color: #eaeaea;
  color: #333; */
}

.navbar-list a {
  padding: 0.8rem;
  color: #eaeaea;
  border-radius: 10px;
  text-decoration: none;
  margin: 0 1rem;
}

.navbar-list a:hover {
  background-color: #eaeaea;
  color: #333;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.navbar-btn-wrapper.open {
  display: none;
}

.navbar-btn-wrapper.close {
  display: none;
}

.navbar-btn {
  background-color: transparent;
  border: unset;
  cursor: pointer;
}

.navbar-btn-close {
  background-color: transparent;
  border: unset;
  cursor: pointer;
  display: none;
}

/* Hero Styles */

.hero-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
  margin-top: 1rem;
  margin-left: -2rem;
  margin-right: 1rem;
}

.hero-col {
  width: 80%;
  padding: 2rem;
  margin-left: 3rem;
  font-size: 1.7rem;
  border: 1px #eaeaea solid;
  border-radius: 10px;
  background-color: #000;
}

/* Project Styles */

#projects-section {
  height: 100vh;
  margin-top: 3rem;
}

#projects-section h1 {
  margin: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaeaea;
  font-weight: bold;
  font-size: 2rem;
}

/* Carousel Design */

.slider {
  position: relative;
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 10px;
  padding: 4rem;
  margin: 0 3rem;
  border: 1px solid #eaeaea;
}

.carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel h3 {
  font-size: 2rem;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 2rem;
  padding-bottom: 5px;
  text-align: center;
}

.carousel img {
  width: 400px;
  height: 200px;
  border-radius: 10px;
  opacity: 0;
  transition-duration: 1s ease;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.carousel.active img {
  opacity: 1;
  transition-duration: 1s ease;
}

.carousel.active img:hover {
  transform: scale(1.02);
  transition: ease-in-out 0.5s;
  border-radius: 10px;
}

.view-code {
  display: block;
  text-align: center;
  margin: 2rem 14rem;
  padding: 1rem 0;
  border: 1px solid #eaeaea;
}

.view-code-btn {
  display: block;
  background: unset;
  color: #eaeaea;
  text-decoration: unset;
  border: unset;
  margin-left: 1rem;
  margin-right: 1rem;
}

.view-code:hover {
  transform: scale(1.03);
  transition: ease-out 0.5s;
}

.view-code-btn:hover {
  cursor: pointer;
}

.carousel p {
  width: 500px;
  margin-top: 2rem;
}

.arrow-left {
  position: absolute;
  top: 35%;
  left: 32px;
  font-size: 2.5rem;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.arrow-right {
  position: absolute;
  top: 35%;
  right: 32px;
  font-size: 2.5rem;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.arrow-left:hover {
  transform: scale(1.1);
  transition: ease-in-out 0.2s;
}

.arrow-right:hover {
  transform: scale(1.1);
  transition: ease-in-out 0.2s;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s ease;
  transform: scale(1.08);
}

/* About Styles */
#about-wrapper {
  height: 110vh;
  margin: 3rem;
}
.about {
  margin-bottom: 2rem;
}

.about h1 {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px #eaeaea solid;
}

.about p {
  line-height: 1.6rem;
  border: 1px solid #eaeaea;
  background-color: #000;
  border-radius: 10px;
  padding: 2rem;
}

.language-skill-section {
  display: flex;
  justify-content: space-evenly;
  background-color: #000;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 1rem;
}

.language-skill-section h2 {
  margin-bottom: 1rem;
}

.dev-icons {
  display: inline-block;
  border-radius: 5px;
  margin-right: 1rem;
  color: #eaeaea;
  padding: 0.3rem;
  background-color: #333;
}

.dev-icons:hover {
  color: #000;
}

.dev-icons-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

#about-wrapper p {
  font-size: 1rem;
}

/* Contact Page */

#contact-wrapper {
  height: 70vh;
  margin: 1rem 2rem;
}

#contact-wrapper h1 {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1rem;
}

.row {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  border-radius: 5px;
  background-color: #000;
  height: 60.1vh;
}

/* Form Styling */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: 60vh;
  padding: 3rem;
  border-radius: 5px;
  border: unset;
  margin-bottom: 5rem;
  margin-right: 5rem;
  margin-left: 5rem;
  border: 1px #eaeaea solid;
}

.container h3 {
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  margin-top: -1rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
}

.container label {
  display: flex;
  margin-bottom: 0.5rem;
}

.container input[type="text"],
input[type="email"] {
  display: block;
  margin: 2rem auto;
  border-radius: 5px;
  height: 5vh;
  padding-left: 1rem;
  width: 80%;
  border: unset;
  outline: unset;
}

.container textarea {
  display: block;
  margin: 1rem auto;
  width: 80%;
  height: 12vh;
  padding-top: 8px;
  padding-left: 1rem;
  resize: none;
  border: unset;
  outline: unset;
  border-radius: 5px;
}

/* Button Styling */

.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.container input[type="submit"] {
  width: 6rem;
  height: 8vh;
  border-radius: 10px;
  border: none;
  background-color: #eaeaea;
  color: #333;
}

.container input[type="submit"]:hover {
  cursor: pointer;
  background-color: #333;
  color: #eaeaea;
}

/* Footer Styling */

.footer-wrapper {
  height: 25vh;
  border-top: #eaeaea 1px solid;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
}

.footer-left {
  line-height: 1.6rem;
  margin-left: 5rem;
}

.footer-right {
  display: flex;
  margin-right: 4rem;
}

.dev-icons-footer {
  display: inline-block;
  margin-right: 1rem;
  color: #eaeaea;
  padding: 0.3rem;
  border-radius: 5px;
  background-color: #333;
}

.dev-icons-footer:hover {
  color: #000;
}

.home-page-return {
  background-color: #333;
  border-radius: 5px;
}

.home-page-return:hover {
  cursor: pointer;
}

/* Responsive Design */

@media screen and (max-width: 768px) {
  #root {
    background: url(/static/media/backgroundimage.cd576bd4.jpg) no-repeat center center fixed;
    background-size: cover;
    color: #eaeaea;
    width: 100%;
    max-width: 768px;
  }

  /* Navbar styles */

  #navbar-wrapper.close {
    display: flex;
    justify-content: space-between;
    height: 13vh;
    margin: 0 2rem;
    border-bottom: 5px solid #eaeaea;
  }

  #navbar-wrapper.open {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 29vh;
    margin: 0 2rem;
    padding-top: 1rem;
    border-bottom: unset;
  }

  .navbar-title.open {
    font-size: 0.7rem;
    border-bottom: 2px #eaeaea solid;
    margin-bottom: 7rem;
    margin-right: 1rem;
  }

  .navbar-list.close li {
    display: none;
  }

  .side-menu.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .side-menu.close {
    border-left: 3px solid #eaeaea;
    border-bottom: 3px solid #eaeaea;
    padding-bottom: 1.5rem;
    width: 10rem;
    height: 13rem;
  }

  .navbar-list.open {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .navbar-btn-wrapper.open {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eaeaea;
    margin-right: 2rem;
  }

  .navbar-btn-wrapper.close {
    display: flex;
    color: #eaeaea;
    margin-right: 2rem;
  }

  .navbar-btn-close {
    display: block;
  }

  .navbar-btn:hover {
    border-bottom: 1px solid #eaeaea;
    transition: ease-in 0.2s;
  }

  .navbar-btn-close:hover {
    border-bottom: 1px solid #eaeaea;
    transition: ease-in 0.2s;
  }

  /* Hero Styling */

  .hero-wrapper {
    margin-left: -2rem;
    margin-top: -2rem;
  }

  .hero-col {
    font-size: 1.1rem;
  }

  /* Project Styling */

  .slider {
    position: relative;
    display: flex;
    height: 80vh;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 10px;
    margin: 0 3rem;
    border: 1px solid #eaeaea;
    min-width: 300px;
  }

  .carousel h3 {
    font-size: 1.3rem;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 2rem;
    padding-bottom: 5px;
    text-align: center;
  }

  .carousel img {
    width: 200px;
    height: 100px;
    border-radius: 10px;
    opacity: 0;
    transition-duration: 1s ease;
    margin-bottom: 2rem;
  }

  .carousel p {
    width: 200px;
    font-size: 0.8rem;
    margin-top: 1rem;
    line-height: 1.5;
  }

  .arrow-left {
    top: 30%;
    left: 32px;
    font-size: 2rem;
  }

  .arrow-right {
    top: 29%;
    right: 32px;
    font-size: 2rem;
  }

  .view-code {
    margin: 1rem;
  }

  /* About Styling */

  #about-wrapper {
    height: 200vh;
  }

  .about h1 {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px #eaeaea solid;
  }

  .about p {
    line-height: 1.6rem;
    font-size: 4px;
  }

  .language-skill-section {
    display: flex;
    flex-direction: column;
  }

  .language-skill-section h2 {
    margin-bottom: 1rem;
  }

  .skill-section {
    margin-top: 3rem;
  }

  #about-wrapper p {
    font-size: 0.9rem;
  }

  /* Contact Styling */

  .contact-wrapper {
    height: 100vh;
  }

  .container {
    height: 55vh;
    padding: 2rem;
  }

  .container h3 {
    margin-top: 0rem;
    font-size: 1.5rem;
  }

  .container input[type="text"],
  input[type="email"] {
    display: block;
    margin: 1.5rem auto;
    border-radius: 5px;
    height: 5vh;
    padding-left: 1rem;
    width: 85%;
    border: unset;
    outline: unset;
  }

  .container textarea {
    width: 85%;
  }

  /* Button Styling */

  .btn-wrapper {
    margin-top: 1.5rem;
  }

  .container input[type="submit"] {
    width: 5rem;
    height: 6vh;
  }

  /* Footer Section */

  .footer-wrapper {
    margin-top: 2rem;
  }

  .footer-left {
    line-height: 1.6rem;
    margin-left: 3rem;
    font-size: 0.7rem;
  }

  .footer-right {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 4rem;
  }

  .dev-icons-footer {
    margin-right: 0.5rem;
    margin-top: 0.2rem;
  }
}

@media screen and (max-width: 450px) and (min-width: 300px) {
  /* Navbar Styling */

  #navbar-wrapper.close {
    align-items: center;
  }

  #navbar-wrapper.open {
    padding-top: 0rem;
  }

  .navbar-title.open {
    font-size: 0.5rem;
    padding-top: 1rem;
  }
  .navbar-title.close {
    font-size: 0.7rem;
  }

  .navbar-list a {
    font-size: 0.9rem;
  }

  /* Hero Styling */

  .hero-col {
    font-size: 1rem;
  }

  /* Project Styling */

  .slider {
    margin-left: 2.3rem;
  }

  .carousel h3 {
    font-size: 1rem;
  }
  .carousel img {
    width: 150px;
    height: 75px;
  }

  .arrow-left {
    top: 35%;
    font-size: 1.3rem;
  }

  .arrow-right {
    top: 35%;
    font-size: 1.3rem;
  }

  .carousel p {
    font-size: 0.5rem;
  }

  .view-code-btn {
    font-size: 0.4rem;
  }

  /* About Styling */

  #about-wrapper {
    height: 240vh;
  }

  #contact-wrapper {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 3000px) {
  #root {
    width: 100%;
    max-width: 3820px;
  }

  #navbar-wrapper {
    border-bottom: 10px #eaeaea solid;
  }

  .navbar-title {
    font-size: 4rem;
  }

  .navbar-list {
    margin-top: 2rem;
  }

  .navbar-list a {
    font-size: 3rem;
    padding: 3rem;
  }

  .hero-col {
    font-size: 5rem;
  }

  #projects-section h1 {
    font-size: 5rem;
  }

  .carousel h3 {
    font-size: 7rem;
    border-bottom: 10px solid #eaeaea;
    margin-bottom: 2rem;
    padding-bottom: 5px;
    text-align: center;
  }

  .carousel img {
    width: 1500px;
    height: 750px;
    border-radius: 10px;
    opacity: 0;
    transition-duration: 1s ease;
    margin-bottom: 2rem;
  }

  .carousel p {
    width: 1500px;
    font-size: 3.5rem;
    margin-top: 1rem;
    line-height: 1.5;
  }

  .arrow-left {
    top: 35%;
    left: 32px;
    font-size: 8rem;
  }

  .arrow-right {
    top: 35%;
    right: 32px;
    font-size: 8rem;
  }

  .view-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    height: 100px;
    width: 200px;
  }

  .view-code-btn {
    font-size: 1.5rem;
  }

  #about-wrapper h1 {
    font-size: 5rem;
  }

  .language-skill-section {
    display: flex;
    flex-direction: column;
    height: 75vh;
  }

  .language-skill-section h2 {
    font-size: 7rem;
  }

  .dev-icons-wrapper {
    font-size: 5.5rem;
  }

  .dev-icons {
    font-size: 5rem;
  }

  #about-wrapper p {
    font-size: 3rem;
    line-height: 1.6;
  }
}

